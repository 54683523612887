@import url("https://fonts.googleapis.com/css2?family=Cormorant+Unicase&family=Nuosu+SIL&display=swap");
@font-face {
  font-family: "MyFont";
  src: local("MyFont"),
    url("/Users/chulchong/cs/wedding/src/util/Metropolis-6Y5M1.ttf")
      format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "JesofinSans";
  src: local("JesofinSans"),
    url("/Users/chulchong/cs/wedding/src/util/JosefinSans-Regular.ttf")
      format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "JesofinSansThin";
  src: local("JesofinSansThin"),
    url("/Users/chulchong/cs/wedding/src/util/JosefinSans-Light.ttf")
      format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Cormorant+Unicase&display=swap");
#WeddingPic {
  display: block;
  width: 98vw;
  margin: auto;
}
#WeddingPicMobile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 98vw;
}
.centered {
  font-family: "JesofinSans";
  position: relative;
  width: 98vw;
  color: white;
  font-size: 3vw;
  text-align: center;
  bottom: 60vw;
  left: 20px;
}
.centeredMobile {
  font-family: "JesofinSans";
  position: relative;
  width: 98vw;
  color: white;
  font-size: 4vw;
  text-align: center;
  bottom: 60vw;
  left: 5px;
}

.headerNext {
  font-family: "Cormorant Garamond", serif;
}

.weddingwho {
  font-family: "JesofinSansThin";
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  text-align: center;
}

.weddingwhoMobile {
  font-family: "JesofinSansThin";
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  text-align: center;
  margin-top: 5vh;
}

.ourname {
  font-size: 4vw;
}

.ournameMobile {
  font-size: 40px;
}

.row {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
}

.rowMobile {
  margin-top: 40px;
  display: flex;
}

.columnMobile {
  flex: 50%;
  text-align: center;
}

.hrvertical {
  margin-top: 0px;
  height: 550px;
  width: 10px;
  border: 3px;
  border-style: none double none none;
  position: absolute;
  right: 50%;
}

.hrhorizantal {
  height: 3px !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  background-color: #f7f5f2 !important;
  width: 70% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  opacity: 1;
  margin: 0;
}

.hrhorizantalMobile {
  height: 3px !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  background-color: #f7f5f2 !important;
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 1;
  margin: 0;
}

.hrverticalMobile {
  margin-top: 0px;
  height: 270px;
  width: 10px;
  border: 3px;
  border-style: none double none none;
  position: absolute;
  right: 50%;
}

.paddingleft {
  margin-left: 9vw;
}

.paddingright {
  margin-right: 8vw;
}

.footer {
  font-family: "JesofinSansThin";
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.7rem;
}

.footermobile {
  font-family: "JesofinSansThin";
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.7rem;
}

.nav-link {
  color: black;
  font-size: 0.9rem;
}

.nav-link:hover {
  color: black;
}
