@import url("https://fonts.googleapis.com/css2?family=Cormorant+Unicase&family=Nuosu+SIL&display=swap");

.RSVP {
  text-align: center;
  vertical-align: middle;
  margin-top: 4vh;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  font-size: 1.3rem;
  font-family: "Cormorant Garamond", serif;
}

.RSVPMobile {
  text-align: center;
  vertical-align: middle;
  margin-top: 4vh;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-size: 1.1rem;
  font-family: "Cormorant Garamond", serif;
}

.IsRSVP {
  text-align: center;
  vertical-align: middle;
  margin-top: 4vh;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-size: 1.3rem;
  font-family: "Cormorant Garamond", serif;
}
.formlabel {
  margin-left: 10px;
  text-align: center;
  width: 100%;
}
.IsRSVPMobile {
  text-align: center;
  vertical-align: middle;
  margin-top: 4vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-family: "Cormorant Garamond", serif;
}
.formcontrol {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 40px;
  margin-top: 30px;
}

.formcontrolMobile {
  margin-left: 15px;
  margin-right: auto;
  margin-top: 20px;
  width: 100%;
  font-size: 30px;
}

.radiobutton {
  padding-top: 10px;
  margin-left: 20px;
  margin-right: auto;
  width: 100%;
}
.radiobuttonMobile {
  margin-left: 20px;
  margin-right: auto;
  width: 100%;
}

.menuradiobutton {
  padding-top: 10px;
  margin-left: 25px;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
}
.menuradiobuttonMobile {
  margin-left: 20px;
  margin-right: auto;
  font-size: 1rem;
  width: 100%;
}

.eachbutton {
  margin-right: 50px;
}
.eachbuttonMobile {
  margin-right: 20px;
  padding-bottom: 10px;
}

.inputbox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.inputboxMobile {
  margin-left: 10px;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.modal {
  font-family: "Cormorant Garamond", serif;
  text-align: center;
}

.hrhorizantalMobile {
  height: 3px !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  background-color: #f7f5f2 !important;
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  opacity: 1;
  margin: 0;
}

.hrhorizantalRSVP {
  height: 3px !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  background-color: #f7f5f2 !important;
  width: 30% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  opacity: 1;
  margin: 0;
}
