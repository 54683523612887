.Youtubevideo {
  display: block;
  padding: 0px !important;
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
}

.YoutubevideoMobile {
  display: block;
  padding: 0px !important;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
}

.video {
  padding-top: 30px;
}
