@import url("https://fonts.googleapis.com/css2?family=Cormorant+Unicase&family=Nuosu+SIL&display=swap");

.weddinginfo {
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  font-family: "Cormorant Garamond", serif;
  text-align: center;
  color: black;
  font-size: 1.3rem;
}
.title {
  margin-top: 20px;
}
.time .nameofaddress .address {
  margin-bottom: 20px;
}
.message {
  margin-top: 30px;
  margin-bottom: 30px;
}

.weddinginfoMobile {
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
  font-family: "Cormorant Garamond", serif;
  text-align: center;
  color: black;
  font-size: 1.3rem;
}
.titleMobile {
  margin-top: 20px;
}
.timeMobile {
  font-size: 17px;
}
.messageMobile {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 17px;
}
