@font-face {
  font-family: "MyFont";
  src: local("MyFont"),
    url("/Users/chulchong/cs/wedding/src/util/Metropolis-6Y5M1.ttf")
      format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Cormorant+Unicase&display=swap");

.navbutton {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  color: black !important;
  font-size: 1.3rem;
}

.navbuttonMobile {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  color: black !important;
}
.NavBrand {
  font-family: "JesofinSans";
  text-align: center !important;
  font-size: 60px !important;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: -10px;
  color: black !important;
}
.NavBrandTablet {
  font-family: "JesofinSans";
  text-align: center !important;
  font-size: 40px !important;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: -10px;
  color: black !important;
}
.NavBrandMobile {
  display: inline-block !important;
  font-family: "JesofinSans";
  font-size: 25px !important;
  color: black !important;
  height: 50px;
  margin-top: 5px;
  padding-top: 10px;
  margin-left: auto;
  margin-bottom: auto;
}
.hr {
  height: 3px !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  background-color: #f7f5f2 !important;
  width: 98vw;
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 1;
  margin: 4px;
}
.NavbarStyle {
  font-family: "Cormorant Garamond", serif;
  text-align: center !important;
}

#navbar {
  width: 100%;
}
.headerheader {
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  font-family: "Cormorant Garamond", serif;
  font-size: 1.3rem;
}
.chultake {
  font-family: "Cormorant Garamond", serif;
  float: right;
  font-size: 1.3rem;
}
.container {
  margin-left: 0px;
  margin-right: auto;
  width: 100%;
  justify-content: center !important;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.menubtn {
  float: right;
  margin-top: 1rem;
  margin-right: 10px;
  font-size: 1.5rem;
}

.menubtnTablet {
  float: right;
  margin-top: 1.5rem;
  margin-right: 10px;
  font-size: 1.5rem;
}
